import type { Country } from '@backmarket/http-api'

import { FIELDS_TAB_INDEX } from '../../constants/fields'
import {
  type FieldNameOrder,
  type FieldsNameOrder,
} from '../../types/fieldName'

export function getFieldsTabIndex({
  country,
}: {
  country: Country
}): FieldsNameOrder {
  return FIELDS_TAB_INDEX[country as keyof typeof FIELDS_TAB_INDEX]
}

export function getFieldTabIndex({
  country,
  fieldName,
}: {
  country: Country
  fieldName: FieldNameOrder
}): number | undefined {
  const fieldTabIndex = getFieldsTabIndex({ country })

  return fieldTabIndex && fieldTabIndex[fieldName]
}
