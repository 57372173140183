<template>
  <!-- FIXME: Set a proper `clear-button-aria-label` value. -->
  <RevInputPhone
    :id="id"
    v-bind="$attrs"
    clear-button-aria-label=""
    data-cs-mask
    :default-country="defaultCountry"
    :description="description"
    :label="labelText"
    :model-value="modelValue"
    :translate="i18n.country"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { Country } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInputPhone } from '@ds/components/InputPhone'

import phoneTranslations from '../../../translations/phone.translations'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    defaultCountry: Country
    description?: string
    id?: string
    label?: string
    modelValue: string
  }>(),
  {
    description: '',
    id: 'phone',
    label: '',
  },
)

const i18n = useI18n()

const labelText = computed(() => props.label || i18n(phoneTranslations.label))
</script>
