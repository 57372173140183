import { Country } from '@backmarket/http-api/src/standards/Country'

export const CHARACTERS_RULE = {
  [Country.US]: /^[a-zA-Z\u0027 -]*$/,
  [Country.FR]: /^[a-zA-Z\u00C0-\u00FF\u0027 -]*$/,
  [Country.GB]: /^[a-zA-Z\u00C0-\u00FF\u0027 -]*$/,
  [Country.DE]: /^[a-zA-Z\u00C0-\u00FF\u0027 -]*$/,
  [Country.ES]: /^[a-zA-Z\u00C0-\u00FF\u0027 -]*$/,
  [Country.PT]: /^[a-zA-Z\u00C0-\u00FF\u0027 -]*$/,
  [Country.IT]: /^[a-zA-Z\u00C0-\u00FF\u0027 -]*$/,
}
export const MIN_LENGTH_RULE = /^.{2,}$/
export const MAX_LENGTH_RULE = /^(?!.{50,})/
export const NOT_A_NUMBER_RULE = /[^0-9]/
